import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Grid, Button, Alert, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../../shared/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../../shared/useAuth";
import AccountSetup from "./components/AccountSetup";
import GiftDeliveryDetails from "./components/GiftDeliveryDetails";
import ExclusiveAddons from "./components/ExclusiveAddons";
import PaymentInformation from "./components/PaymentInformation";
import OrderSummary from "./components/OrderSummary";
import useFormData from "./hooks/useFormData";
import useTotalPrice from "./hooks/useTotalPrice";
import { handleSubmit } from "./utils/checkoutHelpers";
import ContainerBox from "../../shared/ContainerBox";

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { user, loading } = useAuth();
  const { formData, handleChange, error, setError } = useFormData();
  const totalPrice = useTotalPrice(formData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);
  const [subIsLoading, setSubIsLoading] = useState(true);
  const [showSubscriptionMessage, setShowSubscriptionMessage] = useState(false);

  // Redirect for null questionnaire data
  const questionnaireData = JSON.parse(
    localStorage.getItem("questionnaireData")
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserSubscription = async () => {
      if (user) {
        try {
          setSubIsLoading(true);
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userData = userDoc.data();
          setUserSubscription({
            subscriptionSource: userData?.subscriptionSource || 0,
            subscriptionLevel: userData?.subscriptionLevel || 0,
          });
        } catch (error) {
          console.error('Error fetching user subscription:', error);
          setError('Failed to load user subscription. Please try again.');
        } finally {
          setSubIsLoading(false);
        }
      } else {
        setSubIsLoading(false);
      }
    };

    fetchUserSubscription();
  }, [user]);

  useEffect(() => {
    if (userSubscription && userSubscription.subscriptionLevel > 0) {
      const timer = setTimeout(() => {
        setShowSubscriptionMessage(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [userSubscription]);

  if (!questionnaireData) {
    navigate("/questionnaire");
  }

  if (loading || subIsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (userSubscription && userSubscription.subscriptionLevel > 0) {
    if (!showSubscriptionMessage) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <ContainerBox>
        <Typography variant="h5" gutterBottom>
          You have an active digital subscription!
        </Typography>
        <Typography variant="body1" paragraph>
          As a subscriber, you can create a new album and share it with anyone you'd like without going through this checkout process.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/album/create")}
        >
          Create an album
        </Button>
      </ContainerBox>
    );
  }

  return (
    <ContainerBox
      component="form"
      onSubmit={(e) =>
        handleSubmit(
          e,
          stripe,
          elements,
          formData,
          setError,
          setIsSubmitting
        )
      }
      id="payment-form"
    >
      <Grid
        container
        sx={{
          maxWidth: { md: 1200 },
          margin: "auto",
          padding: { xs: 0, sm: 0, md: "1rem" },
        }}
      >
        <Grid item xs={12} md={8}>
          <AccountSetup formData={formData} handleChange={handleChange} />
          <GiftDeliveryDetails
            formData={formData}
            handleChange={handleChange}
          />
          <ExclusiveAddons formData={formData} handleChange={handleChange} />
          <PaymentInformation />
          {error && (
            <Alert severity="error" sx={{ mt: 6 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ mt: 8 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!stripe || isSubmitting}
            >
              {isSubmitting ? "Processing..." : "Pay"}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <OrderSummary formData={formData} totalPrice={totalPrice} />
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default Checkout;
